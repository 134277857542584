/* line 13, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricepersqftchart/module.scss */
.m-marketdata-sales-price {
	margin-bottom: 20px;
}

/* line 17, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricepersqftchart/module.scss */
.marketdata-sales-price__chart-title {
	margin-bottom: 10px;
	text-transform: capitalize;
}

/* line 21, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricepersqftchart/module.scss */
.marketdata-sales-price__chart-title--colored {
	color: rgb(var(--color-global-text));
}

/*# sourceMappingURL=../../../../../true */